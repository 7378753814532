$BLOB_URL:"https://elysiumdu.blob.core.windows.net/flyos/";
// $BLOB_URL:"https://app.vampirethemasquerade-chapters.com/data/";

@font-face {
    font-family: 'ChaptersIconsMix';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/ChaptersIconsMix.otf');
}

@font-face {
    font-family: 'ClansIcons';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/ClansIcons.otf');
}

@font-face {
    font-family: 'CrimsonPro-Black';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-Black.ttf');
}

@font-face {
    font-family: 'CrimsonPro-BlackItalic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-BlackItalic.ttf');
}

@font-face {
    font-family: 'CrimsonPro-Bold';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-Bold.ttf');
}

@font-face {
    font-family: 'CrimsonPro-BoldItalic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-BoldItalic.ttf');
}

@font-face {
    font-family: 'CrimsonPro-ExtraBold';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-ExtraBold.ttf');
}

@font-face {
    font-family: 'CrimsonPro-ExtraBoldItalic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-ExtraBoldItalic.ttf');
}

@font-face {
    font-family: 'CrimsonPro-Italic-VariableFont_wght';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-Italic-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'CrimsonPro-Italic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-Italic.ttf');
}

@font-face {
    font-family: 'CrimsonPro-Light';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-Light.ttf');
}

@font-face {
    font-family: 'CrimsonPro-LightItalic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-LightItalic.ttf');
}

@font-face {
    font-family: 'CrimsonPro-Medium';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-Medium.ttf');
}

@font-face {
    font-family: 'CrimsonPro-MediumItalic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-MediumItalic.ttf');
}

@font-face {
    font-family: 'CrimsonPro-Regular';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-Regular.ttf');
}

@font-face {
    font-family: 'CrimsonPro-SemiBold';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-SemiBold.ttf');
}

@font-face {
    font-family: 'CrimsonPro-SemiBoldItalic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-SemiBoldItalic.ttf');
}

@font-face {
    font-family: 'CrimsonPro-VariableFont_wght';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/CrimsonPro-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'DisciplineIcons';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/DisciplineIcons.otf');
}

@font-face {
    font-family: 'Ghastly Panic';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Ghastly Panic.ttf');
}

@font-face {
    font-family: 'Oswald-Bold';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Oswald-Bold.ttf');
}

@font-face {
    font-family: 'Oswald-DemiBold';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Oswald-DemiBold.ttf');
}

@font-face {
    font-family: 'Oswald-Heavy';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Oswald-Heavy.ttf');
}

@font-face {
    font-family: 'Oswald-Light';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Oswald-Light.ttf');
}

@font-face {
    font-family: 'Oswald-Regular';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Oswald-Regular.ttf');
}

@font-face {
    font-family: 'Oswald-SemiBold';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Oswald-SemiBold.ttf');
}

@font-face {
    font-family: 'Kingthings Trypewriter';
    src: url($BLOB_URL + 'upload/images/64ed011e17b7622c4d323360/fonts/Kingthings Trypewriter 2.ttf');
}